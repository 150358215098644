import React, { useEffect, useState } from "react";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   Button,
//   useDisclosure,
//   Box,
//   Flex,
// } from "@chakra-ui/react";
import "react-medium-image-zoom/dist/styles.css";
///FAILING UNIT TESTS:: import Zoom from "react-medium-image-zoom";

import Image from "next/image";
import { IImageTopicFields } from "src/types/generated/contentful";
import styled from "styled-components";

import FlexDisplayItem from "../FlexDisplayItem";
import { fixImagePath } from "src/utils/dom";
//import { motion } from "framer-motion";

const ImageTopic = (data: any) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  const [compData, setCompData] = useState<IImageTopicFields>(data);
  const [imageFields, setImageFields] = useState(data?.image?.fields);
  const [imageUrl, setImageUrl] = useState<string>(
    fixImagePath(imageFields?.file.url)
  );

  useEffect(() => {
    setCompData(data);
    setImageFields(data?.image?.fields);
  }, [data]);

  useEffect(() => {
    setImageUrl(fixImagePath(imageFields?.file.url));
  }, [imageFields?.file.url]);

  // TODO: add layout, intrinsic, responsive, fixed, fill, etc.

  return (
    <>
      {imageUrl ? (
        <FlexDisplayItem
          data-layoutid={data["data-layoutid"]}
          fh={
            compData?.flexDisplayHorizontalAlignment?.toLowerCase() ||
            "flex-start"
          }
          fv={
            compData?.flexDisplayVerticalAlignment?.toLowerCase() ||
            "flex-start"
          }
          fw={imageFields.fw}
          data-cid={data?.id || data["data-layoutid"]}
        >
          {/*  <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 0.6, ease: "easeOut" }}
          >*/}

          <Image
            //onClick={onOpen}
            src={imageUrl}
            alt={imageFields.title}
            title={imageFields.title}
            width={data.width || imageFields?.file?.details?.image?.width}
            height={data.height || imageFields?.file?.details?.image?.height}
            style={{ borderRadius: data.radius || 0 }}
            loading="lazy"
            //placeholder="blur"
            //blurDataURL={`https:${imageFields?.file.url}?fit=thumb&q=1`}
          />
          {/* </motion.div>*/}
          {/*}
          <Modal
            isOpen={isOpen}
            size="full"
            scrollBehavior="inside"
            allowPinchZoom={true}
            onClose={onClose}
          >
            <ModalOverlay background="blackAlpha.800" />

            <ModalContent background="transparent">
              <ModalCloseButton color="white" size="lg" />
              <ModalBody>
                <Box p="4">
                  <Flex justifyContent="center" alignItems="center">
                    <Image
                      onClick={onOpen}
                      src={`https:${file.url}`}
                      alt={title}
                      title={description}
                      layout={layout || "intrinsic"}
                      width={width || file?.details?.image?.width}
                      height={height || file?.details?.image?.height}
                      style={{ borderRadius: radius || 0 }}
                      loading="lazy"
                      placeholder="blur"
                      blurDataURL={`https:${file.url}?fit=thumb&q=1`}
                    />
                  </Flex>
                </Box>
              </ModalBody>
            ya</ModalContent>
          </Modal>*/}
        </FlexDisplayItem>
      ) : null}
    </>
  ); // 130x49 130x33
};

export default React.memo(ImageTopic);
